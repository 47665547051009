<template>
    <div class="top-navbar flex w-full items-center">
        <div class="left-containers flex-1"></div>
        <div v-if="formatTime.length > 0" class="countdown flex items-center">
            <!-- <i v-if="clock" class="iconfont">&#xe8a6;</i> -->
            <span class="mr-1">距离{{ platform }}结束: </span>
            <span>{{ formatTime }}</span>
        </div>
        <div class="right-containers flex-1 flex justify-end items-center">
            <el-button v-if="isBack" class="back-button" type="primary" size="small" @click="goBack"> 
                <i class="iconfont pr-1">&#xe62d;</i>
                返回主页面
            </el-button>
            <div class="user-info flex items-center">
                <img class="avatar" :src="userInfo.avatar" :alt="userInfo.username" :title="userInfo.username" >
                <div class="username">{{ userInfo.username }}</div>
            </div>
        </div>
    </div>
</template>

<script>
// import { getTrainExamDetail } from "../../api/index"
export default {
    name: 'StudentTopNavbar',
    components: {},
    props: {
        clock: {
            type: Boolean,
            default: true
        },
        isBack: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            userInfo: {
                username: localStorage.getItem("username"),
                avatar: localStorage.getItem("headerImg")
            },
            // params: {
            //     student_id: localStorage.getItem("Id"),
            //     competition_id: localStorage.getItem("competition_id")
            // }
            startTime: 0,
            endTime: 0,
            timer: -1,
            formatTime: "",
            platform: localStorage.getItem("type") == '1' ? "考试" : "训练"
        }
    },
    computed: {},
    methods: {
        getDetail() {

        },
        setTime() {
            const { endTime, platform } = this;
            const getVal = (value) => {
                const newVal = Math.floor(value) + '';
                return newVal.length <= 1 ? `0${newVal}` : newVal;
            };
            this.timer = setInterval(() => {
                const diffTime = (endTime - new Date().getTime()) / 1000;
                if(diffTime <= 0) {
                    this.$router.push("/");
                    this.$message.error(platform + "已结束");
                    clearInterval(this.timer);
                    return void 0;
                }
                const day = getVal(diffTime / 60 / 60 / 24);
                const hour = getVal(diffTime / 60 / 60 % 24);
                const minute = getVal(diffTime / 60 % 60);
                const second = getVal(diffTime % 60)
                this.formatTime = [day, hour, minute, second].filter(item => Number(item)).join(": ");
            }, 1000)
        },
        getLocalData(key) {
            let value = localStorage.getItem(key);
            return typeof value == "string" && value.trim() && value.trim() !== "undefined" ? JSON.parse(value) : void 0;
        },
        goBack() {
            this.$router.replace("/student/competition/index");
        }
    },
    created() {
        let startTime = this.getLocalData("begin_time");
        let endTime = this.getLocalData("end_time");
        startTime && (this.startTime = startTime.data * 1000);
        endTime && (this.endTime = endTime.data * 1000);
        this.setTime();
    },
}
// $http(getTrainExamDetail, params, (res, msg) => {
//     startTime.value = res.data.begin_time * 1000;
//     endTime.value = res.data.end_time * 1000;
//     let errMsg = "";
//     if(startTime.value > endTime.value) {
//         errMsg = "时间异常：开始时间大于结束时间";
//     }else if(endTime.value < new Date().getTime()) {
//         errMsg = platform + "已结束"
//     }
//     if(errMsg) {
//         router.push("/");
//         ElMessage.error(errMsg);
//         return void 0;
//     }
//     setTime();
// });

</script>

<style scoped lang="scss">
.flex {
    display: flex;
}
.w-full {
    width: 100%;
}
.flex-1 {
    flex: 1;
}
.items-center {
    align-items: center;
}
.justify-end {
    justify-content: flex-end;
}
.mr-1 {
    margin-right: 4px;
}
.top-navbar {
    box-sizing: border-box;
    height: 50px;
    padding: 0 20px;
    box-shadow: 0 3px 6px 1px rgba(0,0,0,.1);
    background-color:#021e39;
    .countdown {
        width: fit-content;
        color: #ccc;
        .iconfont {
            margin-right: 7px;
            font-size: 18px;
        }
    }
    .user-info {
        color: #ccc;
        font-size: 14px;
        .avatar {
            box-sizing: border-box;
            width: 32px;
            height: 32px;
            margin-right: 8px;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid #E6E7EB;
            vertical-align: bottom;
        }
    }
    .right-containers {
        .back-button {
            margin-right: 8px;
            .iconfont {
                margin-right: 5px;
            }
        }
    }
}
</style>
